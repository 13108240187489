import {useRuntimeConfig} from "#app";

export function useAlternateLinks(path: string) {
    const config = useRuntimeConfig()
    const siteHost = config.public.siteHost
    const {locales, locale} = useI18n()

    const alternateLinks = ref(locales.value.filter(loc => ['en', 'ru', 'ch'].includes(loc.code)).reduce((acc, loc) => {
        const baseUrl = `${siteHost}${loc.code === 'en' ? '' : `/${loc.code}`}`
        // if (locale.value !== loc.code) {
        acc.push({
            rel: 'alternate',
            hreflang: loc.code,
            href: baseUrl + path
        });
        // }

        return acc;
    }, [] as any[]));

    alternateLinks.value.push({
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${siteHost}${path}`
    });

    const canonical = ref(`${siteHost}${locale.value === 'en' ? '' : `/${locale.value}`}${path}`);
    alternateLinks.value.push({
        rel: 'canonical',
        href: canonical.value
    });

    return {alternateLinks, canonical}
}
